<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
  >
    <common-forms-create-or-update-media
      :attach-id="currentAttachment.id"
      :is-public="currentAttachment.is_public"
      :file="attachment.url"
      class="grid grid-cols-1 sm:gap-5 gap-2.5"
      @success="onSuccess"
      @set-avatar="$emit('setAvatar', attachment.url)"
    >
      <ui-media-viewer
        class="rounded-lg overflow-hidden"
        :init-index="initIndex"
        :attachments="attachments"
        @select="onSelectMedia"
      />
    </common-forms-create-or-update-media>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type Props = {
    attachments: Attachment[];
    attachment: Attachment;
  };
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue', 'setAvatar']);

  const currentAttachment = ref(props.attachment);

  const { onSuccess } = createModalState(emit);
  const onSelectMedia = (attachment: Attachment) => {
    currentAttachment.value = attachment;
  };

  const initIndex = computed(() => useFindIndex(props.attachments, { id: props.attachment?.id }));
</script>
